
/**fonts for Raleway**/
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-ExtraBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Raleway";
  src: url("assets/fonts/Raleway/Raleway-Black.ttf");
  font-weight: 800;
}

/**fonts for Open Sans**/
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-weight: 800;
}

:root {
  --white:#FFFFFF;
  --secondary: #38B450;
  --primary: #141414;
  --black: #000000;
  --lightblack:'#1A1A1A';
  --cyan:#00BCD0;
  --gray:#4d4c51;
  --lightgray:#E2E2E2;
  --darkgray:#3A435F;
}
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family:"Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*::before,*::after,h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6{
  display: inline-block;
}

*,
html,
body {
  margin: 0;
  padding: 0;
  /* background-color: var(--primary); */
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  scroll-behavior: smooth !important;
}
.novocall-button{
  display: none !important;
}

.timesync .app .container{
  height: 90vh;
  width: 90vw;
}

@media screen and (max-width: 768px) {
  html{
    font-size: 12px;
  }
}

@media screen and (min-width: 1920px) {
  html{
    font-size: 18px;
  }
}
